<template lang="pug">
.about-page
  section.about-hero
    .hero-body.responsive-section
      .title-cta
        h1.title.is-spaced What's your nutworth?
        h2.subtitle
          | Discover the dead simple way to track your assets, liabilities and see into your future.
          | Free sign up. 
        .buttons.are-medium
          router-link.button(:to="{name: 'sign_up'}" v-if="!user")
            strong Get started
          router-link.button(:to="{name: 'entries'}" v-if="user")
            strong Go to My Data
      img.hero-illustration(src="@/assets/img/mydata-minimal.png")
  section.value-adds
    .value-add.track-holdings.responsive-section.no-top-margin
      h2.title Track your holdings
      .illustration
        img(src="@/assets/img/add-items.svg")
      .info.content
        p
          | Add your assets to nutworth. This could be your home, your car,
          | your stock portfolio - whatever you feel makes up your overall net worth.
          | We support holdings in over 300 currencies and cryptocurrencies
          | making data entry a breeze.
        p
          | Add debts if you have any. This could be a student
          | loan, a mortgage, a credit card. Get everything on a single page
          | and stay on top of your finances.
        p
          | We'll remember what you told us and show you your overall position.
          | Repeat this a few times and you'll see your financial journey take shape.
    .value-add.monitor-progress.responsive-section
      h2.title Monitor your progress
      .illustration
        img(src="@/assets/img/monitor-progress.svg")
      .info.content
        p
          | Gain insight into your financial position and how it's changed
          | over the months and years. Our analytics tools will take the
          | information you've given us, break it down by asset class and
          | let you know key figures.
        p
          | Set goals, see what you need to do to meet them. Keep track of
          | your progress towards them every time you add an entry and stay
          | on target.
        p
          | See how fast different holdings of yours are growing - or spot the
          | ones holding you back. With nutworth, you get easy insight into the rate of
          | change of your holdings, enabling you to make better financial
          | decisions. Without good data, we are blind.
    .value-add.chart-journey.responsive-section
      h2.title Chart your future
      .illustration
        img(src="@/assets/img/growth.svg")
      .info.content
        p
          | We've put a tonne of effort into creating an advanced financial
          | simulator that we haven't seen in any other product to date. Add
          | estimated rates of returns, monthly savings, offset accounts and
          | more to your holdings. We'll take that information and then show
          | you what that would mean for the years to come.
        p
          | But it doesn't stop there - experiment with your financial future
          | with our advanced scenario tool. Model things like putting your
          | children through school, selling a house or drawing
          | down on your stocks over a period of years. Make it as simple or
          | as complicated as you like and see how it will change your projected
          | future holdings.
        p
          | Too complicated? We also have automatic analysis available
          | which will do short-term projections with no extra input.
    .responsive-section.cta(v-if="!user")
      router-link.button.is-large(:to="{name: 'sign_up'}" v-if="!user")
        strong Get started now
  section.byod.slant-bg
    .value-add.responsive-section
      h2.title Bring your own data
      h3.subtitle Take it away freely, whenever you like.
      .illustration
        img(src="@/assets/img/spreadsheets.svg")
      .info.content
        p
          | If you've already started tracking your net worth, we'd love for you
          | to give us a try. A lot of us have been trying to track this information
          | in a spreadsheet, which only works up to a point.
        p
          | We have a powerful xlsx importer which will take your existing
          | spreadsheet and pull it into nutworth. Visualise your data and try
          | out our simulator.
        p
          | We strongly believe that you own your own data which is why you can
          | export your data just as easily with a click at any time. You can also
          | freely delete your account at any time with no protest from us.
    .value-add.responsive-section
      h2.title Secure and private
      h3.subtitle Your information is safe with us.
      .illustration
        img(src="@/assets/img/security.svg")
      .info.content
        p
          | We appreciate the need for privacy when dealing with your
          | personal finances. We minimise the amount of personal
          | information that we request from you, only requiring an
          | email address for account management and reminders. Should you
          | upgrade to our pro plan, your billing information will stay with our third
          | party payment processor and will never be stored together with your
          | nutworth account. Passwords are securely hashed and stored isolated from all
          | other data.
    .responsive-section.cta(v-if="!user")
      router-link.button.is-large(:to="{name: 'sign_up'}" v-if="!user")
        strong Create your account now
  about-section-plans
  about-footer
</template>

<script>
import { mapState } from 'vuex'
import AboutSectionPlans from '@/components/AboutSectionPlans'
import AboutFooter from '@/components/AboutFooter'

export default {
  name: 'AboutView',
  components: {
    AboutSectionPlans,
    AboutFooter
  },
  computed: {
    ...mapState('app', ['isTWA']),
    ...mapState('authentication', ['user'])
  },
  mounted() {
    if (this.isTWA) {
      this.$router.replace({ name: 'app_boot' })
    }
  },
  metaInfo: {
    title: 'Net worth tracker',
    meta: [
      {
        name: 'description',
        content: `nutworth lets you track and analyse your net worth with ease.`
      }
    ]
  }
}
</script>

<style lang="sass" scoped>
.about-page
  max-width: 100%
  overflow-x: hidden
.buttons
  display: flex
  justify-content: center
  margin-top: 3rem
.about-hero
  position: relative
  min-height: 80vh
  display: flex
  align-items: center
  justify-content: center
  &:before
    position: absolute
    content: " "
    top: -10%
    left: 0
    height: 100%
    max-height: 95vh
    min-height: 680px
    width: 100%
    background: linear-gradient(to right, #3e895d, 20%, #176d40) #176d40
    //border-radius: 0 0 8rem 8rem
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06), inset 0 -2px 4px 0 rgba(0, 0, 0, 0.06)
    transform: skewY(-3deg)
// Disable the gradient on firefox, because it breaks - note this is v-deep
@-moz-document url-prefix()
  .about-hero:before
    background: #176d40 !important
.slant-bg
  position: relative
  padding: 3rem 0
  .responsive-section
    position: relative
    z-index: 2
    color: white
  h1, h2, h3
    color: white
  &:before
    position: absolute
    content: " "
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
    background: linear-gradient(to right, #3e895d, 20%, #176d40) #176d40
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06), inset 0 -2px 4px 0 rgba(0, 0, 0, 0.06)
.responsive-section
  max-width: min(100%, 1300px)
  margin: 3rem auto
  padding: 0 2rem
  flex-wrap: wrap
.hero-body
  position: relative
  z-index: 1
  display: flex
  justify-content: space-between
  align-items: center
  h1, h2
    color: white
  h2
    line-height: 2rem
.currency-list
  .responsive-section
    display: flex
    flex-direction: column
    align-items: center
  .b-table
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 1px 18px 0px rgba(0,0,0,.12)
    border-radius: 0.5rem
    width: 800px
.hero-illustration
  display: none
  flex-grow: 1
  width: 40%
  max-width: 600px
  background: whitesmoke
  border-radius: 1rem
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0,0,0,.12)
  margin: 2rem 0
.info p
  max-width: 640px
  font-size: 1.2rem
.no-top-margin
  margin-top: 0 !important
.subtitle
  font-size: 1.5rem
.value-add
  .illustration
    padding: 2rem
    width: 100%
    img
      width: 100%
      max-width: 320px
    display: flex
    align-items: center
    justify-content: center
  .title
    grid-area: title
  .subtitle
    grid-area: subtitle
  .content
    grid-area: content
  .illustration
    grid-area: illustration
.cta
  position: relative
  z-index: 4
  display: flex
  flex-direction: row
  justify-content: center
  span
    padding-top: 0.5rem
.slant-bg .cta
  span
    color: white
.buttons
  justify-content: center
  .button
    font-size: 1.5rem !important

@media screen and (min-width: 700px)
  .buttons
    justify-content: flex-start
  .cta
    justify-content: flex-start
  .value-add
    display: grid
    grid-template-areas: "title illustration" "subtitle illustration" "content illustration"

@media screen and (min-width: 950px)
  .hero-illustration
    display: block
    max-width: 600px
  .title-cta
    max-width: 50%
    padding-right: 1.5rem
  .value-add
    flex-wrap: nowrap
    .illustration
      margin: 0 2rem
  .responsive-section
    margin: 6rem auto
  .slant-bg
    padding: 4rem 0
  .cta
    margin-top: -2.5rem
</style>
